import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/Header"

export default () => (
  <Layout>
    <SEO pageTitle="Page not found" />
    <Header pageTitle="Page not found" bgImage="backgrounds/header.jpg" />
    <section className="intro-text">
      <div className="container">
        <p>Sorry, the page you requested doesn't exist.</p>
      </div>
    </section>
  </Layout>
)